import * as React from "react"


import CallUs from "../components/callUsStrap"
import { Link } from "gatsby"

const FullWidthCTA = ({ lsH3,lsBody, rsH3,rsBody,callUsStrapline }) => {

    return (
        <div
            className="container-fluid bg-primary py-5"
            data-sal="fade"
            data-sal-duration="500"
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md mb-3 mb-md-0">
                        <div className="row">
                            <div className="col-12 col-md-9"
                                data-sal-duration="500"
                                data-sal="slide-up"
                                data-sal-delay="200"
                                data-sal-easing="ease"
                            >
                                
                                    {lsH3 ? (
                                    <h4
                                        className="fw-light fs-3 mb-3"
                                        dangerouslySetInnerHTML={{ __html: lsH3 }}
                                    /> 
                                        ) : (
                                                ''
                                    )}
                                
                                    {lsBody ? (
                                    <p className="fw-normal"
                                        dangerouslySetInnerHTML={{ __html: lsBody }}/> 
                                        ) : (
                                                ''
                                    )}
                                    
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md d-flex justify-content-between flex-column "
                    data-sal-duration="500"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease"
                    >
                        <div className="row ">
                            <div className="col-12 col-md-9">

                                    {rsH3 ? (
                                    <h4
                                        className="fw-light fs-3 mb-3"
                                        dangerouslySetInnerHTML={{ __html: rsH3 }}
                                    /> 
                                        ) : (
                                                ''
                                    )}
                                
                                    {rsBody ? (
                                    <p className="fw-normal"
                                        dangerouslySetInnerHTML={{ __html: rsBody }}
                                    /> 
                                    ) : (
                                            ''
                                    )}
                                    
                                
                            </div>
                        </div>
                        <div className="row ">
                            {callUsStrapline ? (
                                <div className="col-12">
                                    <p
                                        className="fw-light fs-4 mb-3"
                                        dangerouslySetInnerHTML={{ __html: callUsStrapline }}
                                    /> 
                                </div>
                                ) : (
                                        ''
                            )}
                            
                            <div className="col-12 col-md-6 my-3 my-md-0">
                                <div className="d-grid gap-2">
                                    <Link to="/contact" className="btn btn-lg btn-light rounded-0" href="/contact">Get in touch</Link>
                                </div>
                            </div>

                            <div className="col  align-middle d-flex align-items-center">
                                <CallUs
                                   
                                    accent={'secondary'}
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FullWidthCTA
