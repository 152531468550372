import React, {  useEffect }  from 'react'

import { graphql } from 'gatsby'


import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import StoryBlock from "../components/titleBlockPages"

import FullWidthCTA from "../components/fullWidthCTABlock"
import FullWidthVideo from "../components/fullWidthVideoBlock"
import OurTeam from "../components/ourTeam"
import Testimonials from "../components/testimonials"
import ContactForm from "../components/footerContactBlock"

const AboutPage = ({ data }) => {

  useEffect(() => {
        


  }, [])

  
  const teamData = data.allTeammembersJson.edges;
  const members = teamData.map((member) => { 
    return member.node;
  })

  const testimonialData = data.allTestimonialsaboutJson.edges;
  const testimonials = testimonialData.map((testimonial) => { 
    return testimonial.node;
  })

  ///page details
  const pageDataEdges = data.allPageaboutJson.edges[0].node;

  return (

    <Layout>
      <Seo
        title="About Us And Our History"
        description="Tkings has been family run since the beginning when we started in the family garden garage. We've come a long way. Join us on our journey >>"
      />
      <TitleBlock
        sectionTitle = {''}
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <div className='container-fluid bg-white'>
        <div className='row'>
          <div className='col py-5 my-5 text-center'><h5
          className='fw-light fs-3'>We’re digital age with strong, traditional principles.</h5></div>
        </div>
        

      </div>
      <FullWidthCTA
        lsH3={'From way back when we started out in a family garden garage to the market-leading streamlined processes you see here today, everything we do centres around you.'}
        lsBody={''}
        rsH3={"What does that mean?"}
        rsBody={"You receive the highest quality products, using advanced technology to meet your precise specifications, along with the added reassurance of a team of friendly folk that genuinely care about the stories of their orders."}
      />
      <FullWidthVideo
        url="https://tkingfiles.fra1.digitaloceanspaces.com/2022_videos/TKING%20-%20Print%20web%20banner%20video%20v01.mp4"
        copy="Everything is created and <br>managed right here in-house."
      />
      <StoryBlock
        sectionTitle = {''}
        pageTitle={pageDataEdges.storyBlockTitle}
        pageSubTitle={pageDataEdges.storyBlockSubTitle}
        pageTitleBody={pageDataEdges.storyBlockBody}
        img={pageDataEdges.storyBlockImg}
      />
      
       
  
      

      <OurTeam
        memberData={members}
      />
      <Testimonials
        bg={"primary"}
        sectionTitleBlock={'What makes us different?'}
        sectionCopy={'More than just pens & polo shirts, we’re experts in brand expression & lasting impressions.'}
        nodes={testimonials}
      />
      <StoryBlock
        sectionTitle = {'Sustainability'}
        pageTitle={pageDataEdges.storyBlockTitle2}
        pageSubTitle={pageDataEdges.storyBlockSubTitle2}
        pageTitleBody={pageDataEdges.storyBlockBody2}
        img={pageDataEdges.storyBlockImg2}
      />
      
      <ContactForm />
    </Layout>
  
  
  
  )
}
export const query = graphql`
  query MyQuery {
    allTeammembersJson {
        edges {
        node {
            name
            jobtitle
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
            }
             imgOver {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
            }
        }
        }
    }
    allTestimonialsaboutJson {
        edges {
        node {
            name
            copy
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
            }
          }
        }
    }
    allPageaboutJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          storyBlockBody
          storyBlockTitle
          storyBlockSubTitle
          storyBlockImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          storyBlockBody2
          storyBlockTitle2
          storyBlockSubTitle2
          storyBlockImg2 {
                childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          
        }
      }
    }
}`
export default AboutPage
