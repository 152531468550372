import * as React from "react"

import CallUs from "../components/callUsStrap"
import { Link } from "gatsby"

const WhyWorkWithUs = ({  copy, url }) => {
    
    
    return (
        <div
            className="videoWrapper position-relative"
            data-sal="fade"
            data-sal-duration="500"
        >
            <video id="theVideo" className="" autoPlay loop muted playsInline   >
                <source src={url} type="video/mp4" />
            </video>
            <div className="container position-absolute bottom-0 start-50 translate-middle-x text-light my-5 videoContent">
                <div className="row">
                    <div className="col-12 col-md-7"
                        data-sal-duration="500"
                        data-sal="slide-up"
                        data-sal-delay="200"
                        data-sal-easing="ease"
                    >
                        <h4
                            className="fs-2 fw-light"
                            dangerouslySetInnerHTML={{ __html: copy }}
                        />
                        <div className="row mt-5">
                            
                            <div className="col-12 mb-3 mb-md-0 col-md-5">
                                <div className="d-grid gap-2">
                                    <Link to="/contact" className="btn btn-lg btn-primary rounded-0" href="/contact">Get in touch</Link>
                                </div>
                            </div>
                            <div className="col align-middle d-flex align-items-center">
                                <CallUs
                                    accent={'primary'}
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyWorkWithUs
