import * as React from 'react'
import {   GatsbyImage, getImage } from "gatsby-plugin-image";

const OurTeamLooper = ({ memberData }) => {
  return (
      <div className="container">
          <div className=""row>
              <h5 className="fw-light fs-3 mt-5">Our team</h5>
          </div>
          <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-4 my-5">
         {memberData.length ? (
              memberData.map((member, index) => {
                const loadedImage = getImage(member.img)
                const loadedImageOver = getImage(member.imgOver)
                  return (
                      <div key={index} className="col">
                      <div
                        className="memberWrapper mb-5"
                        data-sal="fade"
                        data-sal-duration="500"
                      >
                              <div className="memberPhoto">
                              <GatsbyImage
                                  image={loadedImage}
                                  alt={member.name}
                                  
                          />
                          <GatsbyImage
                                  className="overImage"
                                  image={loadedImageOver}
                                  alt={member.name}
                                  
                                  />
                                  </div>
                                <div className="memberDetails mt-3">
                                    <p className="fs-5 fw-light mb-0">{member.name}</p>
                                    <p className=" fw-light mb-0">{member.jobtitle}</p>
                                  </div>
                            </div>
                      </div>
                  )
                        
                    
                })
                ) : (
                <>
                    
                </>
              )}
        </div>
    </div>
  )
}




export default OurTeamLooper